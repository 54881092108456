/*=====================================================
7. wpo-wedding-single-area
======================================================*/
.wpo-wedding-single-area{
    .wpo-wedding-single-wrap{
        .wpo-wedding-single-title{
            h3{
                font-weight: 500;
                font-size: 35px;
                line-height: 130.5%;
                margin-bottom: 20px;

                @media(max-width:767px){
                    font-size: 25px;
                }
            }
        }
        .wpo-wedding-single-item{
            margin-bottom: 40px;
            .wpo-wedding-single-main-img{
                margin-bottom: 30px;
            }

            &:last-child{
                margin-bottom: 0;
            }

        }
        .list-widget{
            max-width: 590px;

            ul{
                list-style: none;

                li{
                    padding: 10px 0;
                    padding-left: 35px;
                    position: relative;

                    &:before{
                       position: absolute;
                       left: 0;
                       top: 50%;
                       transform: translateY(-50%);
                       width: 25px;
                       height: 25px;
                       line-height: 25px;
                       border-radius: 50%;
                       background: transparentize($theme-primary-color, .9);
                       content: "\e64c";
                       font-family: "themify";
                       text-align: center;
                       color: $theme-primary-color;
                       font-size: 14px;
                    }
                }
            }
        }

        .wpo-p-details-img{
            margin-bottom: 10px;
        }

        .wpo-wedding-area{
            padding-bottom: 0;

            .wpo-wedding-item {
                padding: 20px;

                @media(max-width:1200px){
                    padding: 20px 10px;
                  }

                h2{
                    font-weight: 600;
                    font-size: 22px;
                    margin-bottom: 12px;
                    margin-top: 0;

                    @media(max-width:1400px){
                      font-size: 19px;
                      margin-bottom: 10px;
                    }
                    @media(max-width:1200px){
                      font-size: 17px;
                      margin-bottom: 10px;
                    }
                    @media(max-width:991px){
                      font-size: 22px;
                    }
                }
                p{
                    margin-bottom: 5px;
                }
            }
        }
    }
}