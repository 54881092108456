/*--------------------------------------------------------------
8. coming-soon-section
--------------------------------------------------------------*/

.coming-soon-section{

    .coming-soon-wrap{
        padding: 80px;
        position: relative;
        margin-bottom: 40px;
        width: 700px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 0 auto;
        background-size: contain;
        box-shadow: 0px 0px 6.1px 3.9px rgba(28, 31, 86, 0.11);
        padding-top: 40px;
        background: $white;
        z-index: 1;
    
        @media(max-width:767px){
            width: 520px;
            padding: 50px;
        }
        @media(max-width:550px){
            width: 420px;
            padding: 50px 30px;
        }
        @media(max-width:450px){
            width: 295px;
            padding: 60px 20px;
        }


        .shape-1{
            position: absolute;
            left: -140px;
            bottom: -170px;
            z-index: -1;
    
            @media(max-width:575px){
                left: -70px;
                bottom: -70px;
    
                img{
                    max-width: 60%;
                }
            }
        }
        .shape-2{
            position: absolute;
            right: -125px;
            top: -100px;
            z-index: -1;
    
            @media(max-width:575px){
                right: -160px;
                top: -50px;
    
                img{
                    max-width: 60%;
                }
            }
        }
    }

    .wpo-wedding-date {
		text-align: center;
		padding-bottom: 0;

        .countdown-wrapper{
            display: flex;
			justify-content: center;
            margin-bottom: 50px;
            justify-content: space-around;

            @media(max-width:550px){
                margin-bottom: 20px;
            }
        }

		@include media-query(1199px) {
			margin-top: 20px;
		}
		@include media-query(991px) {
			display: flex;
			justify-content: center;
			margin-top: 0;
		}



		.time-section {
			overflow: hidden;
			margin-top: 20px;


			@include media-query(991px) {
                width: 80px;
			}
			@include media-query(530px) {
				margin-top: 0;
                width: 70px;
			}

			> div {
				display: flex;
                justify-content: center;
                flex-direction: column;
			}

			> div + div {
				margin-left: 50px;

				@include media-query(991px) {
					margin-left: 20px;
				}

				@include media-query(767px) {
					margin-left: 10px;
				}
			}

			.time {
				font-family: $heading-font;
				font-size: 60px;
				font-size: calc-rem-value(60);
				line-height: 1em;
				padding-top: 15px;
				color: $text-color;

				@include media-query(1300px) {
					font-size: 40px;
					font-size: calc-rem-value(40);
				}
				@include media-query(767px) {
					font-size: 40px;
					font-size: calc-rem-value(40);
				}
			}

			.time-text{
				color: $text-color;
				font-size: 25px;

				@media(max-width:991px){
					font-size: 20px;
				}
			}
		}
	}


    .coming-soon-text{
        text-align: center;
        h2{
            font-size: 50px;

            @include media-query(767px) {
                font-size: 30px;
            }
        }

        p{
            margin-bottom: 0;
        }
    }

    .wpo-coming-contact{
        .form-control{
          width: 100%;
          height: 55px;
          background: none;
          border: 1px solid #ccc;
          margin-bottom: 20px;

          &:focus{
              outline: none;
              box-shadow: none;
          }
        }

        button{
            height: 55px;
            background: $theme-primary-color;
            border: none;
            color: $white;
            width: 100%;
        }
    }
}