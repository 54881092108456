/*======================================
 5. Home-style-2 
 =======================================*/

/*5.1 couple-section-s2*/
.couple-section-s2 {

    .shape-1,
    .shape-2 {
        display: none;
    }

    .couple-area {
        .couple-item {
            .couple-img {
                padding: 0;
                box-shadow: none;
                position: relative;
                overflow: unset;
                text-align: right;

                @media(max-width:991px) {
                    text-align: center;
                }

                img {
                    width: unset;
                }

                .couple-shape {
                    display: block;
                    position: absolute;
                    left: -27px;
                    top: -11px;
                    width: 110%;

                    @media(max-width:1400px) {
                        left: -54px;
                        top: -10px;
                        width: 120%;
                    }

                    @media(max-width:1200px) {
                        left: -45px;
                        top: -8px;
                        width: 120%;
                    }

                    @media(max-width:991px) {
                        top: -12px;
                        width: 110%;
                    }

                    @media(max-width:475px) {
                        top: -3%;
                        width: 120%;
                        left: -15%;
                    }

                    @media(max-width:375px) {
                        left: -17%;
                    }

                    img {
                        border-radius: 0;
                    }
                }
            }

            &.groom {
                .couple-img {
                    text-align: left;

                    @media(max-width:991px) {
                        text-align: center;
                    }

                    .couple-shape {
                        left: -10px;

                        @media(max-width:991px) {
                            left: 0;
                        }

                        @media(max-width:475px) {
                            left: -5%;
                        }

                        @media(max-width:375px) {
                            left: -3%;
                        }
                    }
                }
            }
        }
    }
}

/*5.2 wpo-wedding-date*/

.wpo-wedding-times {
    text-align: center;
    padding-bottom: 0;
    position: relative;

    @media(max-width:767px) {
        margin-top: 0;
    }


    .clock-grids {
        background: $theme-primary-color-s2;
        position: relative;

        &:before {
            position: absolute;
            left: 1%;
            top: 7%;
            width: 98%;
            height: 87%;
            content: "";
            border: 2px solid $border-color;
        }

        .frame1 {
            position: absolute;
            left: 1%;
            top: 7%;
        }

        .frame2 {
            position: absolute;
            left: 1%;
            bottom: 7%;
        }

        .frame3 {
            position: absolute;
            right: 1%;
            top: 7%;
        }

        .frame4 {
            position: absolute;
            right: 1%;
            bottom: 7%;
        }
    }

    .countdown-wrapper {
        overflow: hidden;
        text-align: center;
        display: inline-block;
        padding: 50px 0;

        @include media-query(1200px) {
            display: flex;
            justify-content: center;
        }

        .time-section {
            width: 280px;
            float: left;
            position: relative;

            @include media-query(1400px) {
                width: 220px;
            }

            &:before {
                position: absolute;
                right: -30px;
                top: 0;
                width: 20px;
                height: 100%;
                content: "";
                background: url(../../images/countdown/shape.jpg) no-repeat center center;

                @include media-query(1400px) {
                    top: -10px;
                    width: 20px;
                    height: 126%;
                }

                @include media-query(767px) {
                    display: none;
                }
            }

            &:last-child {
                &:before {
                    display: none;
                }
            }

        }

        >div+div {
            margin-left: 50px;

            @include media-query(991px) {
                margin-left: 20px;
            }

            @include media-query(767px) {
                margin-left: 10px;
            }
        }

        .time {
            font-family: $heading-font;
            font-size: 60px;
            font-size: calc-rem-value(60);
            line-height: 1em;
            padding-top: 15px;
            color: $dark-gray;

            @include media-query(1200px) {
                font-size: 40px;
                font-size: calc-rem-value(40);
            }

            @include media-query(991px) {
                font-size: 30px;
                font-size: calc-rem-value(30);
            }

            @include media-query(575px) {
                font-size: 25px;
                font-size: calc-rem-value(25);
            }
        }

        .time-text {
            color: #4D4D4D;
            font-size: 25px;

            @include media-query(1200px) {
                font-size: 18px;
            }

            @include media-query(575px) {
                font-size: 14px;
            }
        }
    }
}


/*5.3 wpo-contact-section-s2*/

.wpo-contact-section-s2 {
    padding-top: 170px;

    @media(min-width:991px) {
        padding-bottom: 280px;
    }

    @media(max-width:991px) {
        padding-top: 90px;
    }

    @media(max-width:575px) {
        padding-top: 0px;
    }

    .wpo-rsvp-wrap {
        position: relative;
        z-index: 1;

        .right-img {
            position: absolute;
            right: 5%;
            top: -20%;
            z-index: -1;

            @media(max-width:1200px) {
                right: -7%;
            }

            @media(max-width:991px) {
                display: none;
            }
        }
    }
}


/*5.4 wpo-portfolio-section-s2*/

.wpo-portfolio-section-s2 {
    .portfolio-grids {
        .grid {
            width: 100%;
            float: none;
            padding: 0;

            .img-holder {
                &:before {
                    z-index: 1;
                }

                .hover-content {
                    z-index: 11;
                }
            }
        }

        /** slider controls **/
        .slick-prev,
        .slick-next {
            background-color: transparentize($theme-primary-color, 0.4);
            width: 55px;
            height: 55px;
            z-index: 10;
            border-radius: 50%;
            @include transition-time(0.5s);

            &:hover {
                background-color: transparentize($theme-primary-color, .2);
            }
        }

        .slick-prev {
            left: -100px;

            &:before {
                font-family: "themify";
                content: "\e629";
                opacity: 1;
            }
        }

        .slick-next {
            right: -100px;

            &:before {
                font-family: "themify";
                content: "\e628";
                opacity: 1;
            }
        }

        &:hover .slick-prev {
            left: 25px;
        }

        &:hover .slick-next {
            right: 25px;
        }
    }
}

// wpo-contact-map-section

.wpo-contact-map-section {
    .wpo-contact-map {
        height: 550px;
    }

    iframe {
        width: 100%;
        height: 100%;
        border: 0;
        outline: 0;
        filter: grayscale(100%);
    }

    h2.hidden {
        display: none;
    }
}