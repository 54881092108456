ul.smothscroll {
    position: fixed;
    bottom: 25px;
    right:30px;
    list-style: none;
    z-index: 99999;
}
ul.smothscroll a {
    width: 45px;
    height: 45px;
    line-height: 45px;
    border-radius:50%;
    display: block;
    text-align: center;
    color: #fff;
    background-color: #705cf2;
    border: 2px solid #705cf2;
}
ul.smothscroll a:hover {
    background-color: #705cf2;
    color: #fff;
}

@media(max-width:767px){
    ul.smothscroll a {
        width: 30px;
        height: 30px;
        line-height: 25px;
    }
}